<template lang="html">
    <div class="card card-primary card-outline TagList">
        <div class="card-header p-2">
            <h3 class="card-title">标签管理</h3>
            <div class="card-tools mr-0">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" v-on:click="search()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- /.box-header -->
        <div class="card-body p-1">
            <div class="mailbox-controls">
                <button type="button" class="btn btn-primary btn-sm mx-1" v-on:click="create()"><i class="fa fa-plus"></i></button>
                <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()"><i class="fas fa-sync"></i></button>
                <div class="float-right">
                    <div class="form-inline visible-md-inline-block visible-lg-inline-block visible-sm-inline-block">
                        <div class="form-group form-group-sm">
                            <label>类型</label>
                            <select class="form-control form-control-sm" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                                <option value="">所有</option>
                                <option v-for="i in types" v-bind:value="i">{{i.key}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- /.pull-right -->
            </div>
            <vue-bootstrap4-table :rows="list"
                                  :columns="columns"
                                  :config="config"
                                  :classes="classes"
                                  @on-change-query="onChangeQuery">
                <template slot="sort-asc-icon">
                    <i class="fas fa-sort-up"></i>
                </template>
                <template slot="sort-desc-icon">
                    <i class="fas fa-sort-down"></i>
                </template>
                <template slot="no-sort-icon">
                    <i class="fas fa-sort"></i>
                </template>
                <template slot="empty-results">
                    没有数据
                </template>
                <template slot="active" slot-scope="props">
                    <button class="btn btn-default btn-xs mx-1" title="编辑" @click="edit(props.row)">
                        <i class="fas fa-pen-square"></i>
                    </button>
                    <button class="btn btn-default btn-xs mx-1" title="删除" @click="opendelete(props.row)">
                        <i class="fas fa-trash"></i>
                    </button>
                </template>
                <template slot="closed" slot-scope="props">
                    {{props.row.closed ? "是" : "否"}}
                </template>
            </vue-bootstrap4-table>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="this.$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <div class="card-footer p-2">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
        </div>
        <Modal ref="Edit">
            <template slot="header">
                <h4 class="modal-title">{{ item.id!=null ? '修改标签':  '新建标签' }}</h4>
            </template>
            <form class="form-horizontal">
                <div class="form-group">
                    <label for="editName" class="col-sm-2 control-label">名称</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="editName" placeholder="名称" v-model.trim="item.name">
                    </div>
                </div>
                <div class="form-group">
                    <label for="type" class="col-sm-2 control-label">标签类型</label>
                    <div class="col-sm-10">
                        <select class="form-control" id="type" placeholder="标签类型" v-model="item.type" v-if="item.guid==null">
                            <option v-for="i in types" :value="i.key">
                                {{i.key}}
                            </option>
                        </select>
                        <input class="form-control" readonly :value="item.type" v-else />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" v-model="item.closed"> 关闭
                            </label>
                        </div>
                    </div>
                </div>
            </form>
            <template slot="footer">
                <button type="button" class="btn btn-default pull-left" @click="$refs.Edit.hide()">取消</button>
                <button type="button" class="btn btn-primary" @click="save()" :disabled="updateing">保存</button>
            </template>
        </Modal>
        <Modal ref="Delete">
            <template slot="header">
                <h4 class="modal-title">删除标签</h4>
            </template>
            <form class="form-horizontal">
                {{item}}
            </form>
            <template slot="footer">
                <button type="button" class="btn btn-default pull-left" @click="$refs.Delete.hide()">取消</button>
                <button type="button" class="btn btn-danger" @click="del(item)" :disabled="updateing">删除</button>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { Modal } from 'common'
    //import Modal from '@/components/Modal'
    export default {
        data() {
            return {
                list: [],
                item: {
                    guid: null,
                    name: null,
                    closed: false,
                    type: '默认',
                },
                columns: [
                    { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
                    { name: 'type', label: '类型', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c_type" },
                    { name: 'name', label: '名称', sort: true, initial_sort: true, initial_sort_order: 'asc', uniqueId: true, column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'addTime', label: '添加时间', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
                    { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
                ],
                classes: {
                    tableWrapper: "table-responsive",
                    table: "table-bordered table-sm table-hover",
                    //row: {
                    //    "my-row my-row2": true,
                    //    "function-class": function (row) {
                    //        return row.id == 1
                    //    }
                    //},
                    //cell: {
                    //    "my-cell my-cell2": true,
                    //    "text-danger": function (row, column, cellValue) {
                    //        return column.name == "salary" && row.salary > 2500
                    //    }
                    //}
                },
                config: {
                    //checkbox_rows: true,
                    //rows_selectable: true,
                    card_mode: false,
                    pagination: false,
                    pagination_info: false,
                    global_search: {
                        visibility: false,
                    },
                    show_refresh_button: false,
                    show_reset_button: false,
                    multi_column_sort: false,
                    server_mode: true,
                },
                pager: {
                    prePageSize: [10, 20, 50, 100],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 20,
                    sort: "Id",
                    order: "desc",
                    fields: 'Name',
                    key: null,
                    roles: "",
                    type: ''
                },
                types: [],
                updateing: false,
            }
        },
        created() {
            this.loadData()
            this.loadTypes()
        },
        methods: {
            loadTypes() {
                var _this = this
                this.$axios.get('/Api/Admin/Tags/Types').then(res => {
                    _this.types = res.data
                })
            },
            loadData() {
                let self = this
                var url = "/Api/Admin/Tags"
                var params = {
                    page: this.query.pageIndex,
                    pageSize: this.query.pageSize,
                    sort: this.query.sort,
                    order: this.query.order,
                    fields: this.query.fields,
                    key: this.query.key,
                    roles: this.query.roles,
                    type: null,
                }
                if (this.query.type)
                    params.type = this.query.type.value
                this.$axios.get(url, { params: params })
                    .then(function (response) {
                        console.log(response)
                        self.list = response.data.data
                        self.pager.pageIndex = response.data.pageIndex
                        self.pager.pageSize = response.data.pageSize
                        self.pager.totalPages = response.data.totalPages
                        self.pager.totalRecords = response.data.totalRecords

                    }).catch(function (error) {
                        console.log(error)
                    })

            },
            search() {
                this.query.pageIndex = 1;
                this.loadData();
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            onChangeQuery(queryParams) {
                var sort = queryParams.sort[0]
                this.query.sort = sort.name
                this.query.order = sort.order
                this.loadData()
            },
            loadItem(id) {
                let self = this
                this.$axios.get('/Api/Admin/Tags/' + id)
                    .then(function (response) {
                        //console.log(response)
                        self.item = response.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            reset() {
                let self = this
                self.item.guid = null
                self.item.name = null
                if (self.query.type && self.query.type != '') {
                    self.item.type = self.query.type.key
                } else {
                    self.item.type = '默认'
                }
                self.item.closed = false
            },
            create() {
                this.$refs.Edit.show()
                this.reset()
            },
            edit(item) {
                console.log(item)
                this.$refs.Edit.show()
                this.loadItem(item.guid)
            },
            opendelete(item) {
                console.log(item)
                this.$refs.Delete.show()
                this.loadItem(item.guid)
            },
            del(item) {
                var _this = this
                _this.updateing = true;
                console.log(item)
                this.$axios.delete('/Api/Admin/Tags/' + item.guid)
                    .then(res => {
                        console.log(res)
                        _this.$refs.Delete.hide()
                        _this.loadData()
                        _this.updateing = false;
                    })
                    .catch(function (error) {
                        console.log(error)
                        _this.updateing = false;
                    })
            },
            save() {
                let self = this
                if (self.item.guid != null) {
                    this.$axios.put('/Api/Admin/Tags/' + self.item.guid, self.item)
                        .then(function (response) {
                            self.reset()
                            self.$refs.Edit.hide()
                            self.loadData()
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    this.$axios.post('/Api/Admin/Tags', self.item)
                        .then(function (response) {
                            self.reset()
                            self.$refs.Edit.hide()
                            self.loadData()
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            },

        },
        components: {
            Modal
        }
    }
</script>

<style lang="css">
    .TagList .c1 {
        width: 90px;
    }

    .TagList .c2 {
        width: 60px;
    }

    .TagList .c_dt {
        width: 155px;
    }

    .TagList .c_type {
        width: 120px;
    }
</style>
