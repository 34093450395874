<template lang="html">
    <div class="card card-primary card-outline RoleList">
        <div class="card-header p-2">
            <h3 class="card-title">产品管理</h3>
            <div class="card-tools mr-0">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" v-on:click="search()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- /.box-header -->
        <div class="card-body p-1">
            <div class="mailbox-controls">
                <button type="button" class="btn btn-primary btn-sm mx-1" v-on:click="create()" title="新建产品"><i class="fa fa-plus"></i></button>
                <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
                <!--<div class="pull-right">
                    <div class="form-inline">
                        <div class="form-group form-group-sm">
                            <label>角色</label>
                            <select class="form-control" v-model:value="query.roles" v-on:change="search()" placeholder="角色">
                                <option value="">所有</option>
                                <option v-for="i in roles" v-bind:value="i">{{i}}</option>
                            </select>
                        </div>
                    </div>
                </div>-->
                <!-- /.pull-right -->
            </div>
            <vue-bootstrap4-table :rows="list"
                                  :columns="columns"
                                  :config="config"
                                  :classes="classes"
                                  @on-change-query="onChangeQuery">
                <template slot="sort-asc-icon">
                    <i class="fas fa-sort-up"></i>
                </template>
                <template slot="sort-desc-icon">
                    <i class="fas fa-sort-down"></i>
                </template>
                <template slot="no-sort-icon">
                    <i class="fas fa-sort"></i>
                </template>
                <template slot="empty-results">
                    没有数据
                </template>
                <template slot="active" slot-scope="props">
                    <button class="btn btn-default btn-xs mx-1" title="编辑" @click="edit(props.row.id)">
                        <i class="fas fa-pen-square"></i>
                    </button>
                    <button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
                        <i class="fas fa-trash"></i>
                    </button>
                </template>
                <template slot="closed" slot-scope="props">
                    {{props.row.closed ? "是" : "否"}}
                </template>
            </vue-bootstrap4-table>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="this.$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <div class="card-footer p-2">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
        </div>
        <Modal ref="Edit">
            <template slot="header">
                <h4 class="modal-title">{{ item.id!=null ? '修改产品':  '新建产品' }}</h4>
            </template>
            <form class="form-horizontal">
                <div class="card-body">
                    <div class="form-group">
                        <label for="editTitle" class="col-sm-2 control-label">名称</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="editTitle" placeholder="名称" v-model.trim="item.title">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="type" class="col-sm-2 control-label">类型</label>
                        <div class="col-sm-10">
                            <select class="form-control" id="type" placeholder="类型" v-model="item.type">
                                <option v-for="i in types" :value="i.key">
                                    {{i.key}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="editOriginalPrice" class="col-sm-2 control-label">原价</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control" id="editOriginalPrice" placeholder="原价" v-model.trim="item.originalPrice">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="editPrice" class="col-sm-2 control-label">价格</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control" id="editPrice" placeholder="价格" v-model.trim="item.price">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="editText" class="col-sm-2 control-label">说明</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="editText" placeholder="说明" v-model.trim="item.text">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-2 col-sm-10">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" v-model="item.closed"> 关闭
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <template slot="footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="save()" :disabled="updateing">保存</button>
            </template>
        </Modal>

        <!-- /.modal -->
    </div>
</template>

<script>
    import { Modal } from 'common'
    //import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },
        data() {
            return {
                list: [],
                item: {
                    id: null,
                    title: null,
                    text: null,
                    type: null,
                    originalPrice: null,
                    price: null,
                },
                types: [],
                columns: [
                    { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
                    { name: 'title', label: '名称', sort: true, initial_sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'type', label: '类型', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'originalPrice', label: '原价', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'price', label: '价格', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                ],
                classes: {
                    tableWrapper: "table-responsive",
                    table: "table-bordered table-sm table-hover",
                    //row: {
                    //    "my-row my-row2": true,
                    //    "function-class": function (row) {
                    //        return row.id == 1
                    //    }
                    //},
                    //cell: {
                    //    "my-cell my-cell2": true,
                    //    "text-danger": function (row, column, cellValue) {
                    //        return column.name == "salary" && row.salary > 2500
                    //    }
                    //}
                },
                config: {
                    //checkbox_rows: true,
                    //rows_selectable: true,
                    card_mode: false,
                    pagination: false,
                    pagination_info: false,
                    global_search: {
                        visibility: false,
                    },
                    show_refresh_button: false,
                    show_reset_button: false,
                    multi_column_sort: false,
                    server_mode: true,
                },
                pager: {
                    prePageSize: [10, 20, 50, 100],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 20,
                    sort: "AddTime",
                    order: "desc",
                    fields: 'Title',
                    key: null
                },
                updateing: false,
            }
        },
        created() {
            this.loadTypes()
            this.loadData()
        },
        computed: {

        },
        methods: {
            loadData() {
                var config = {
                    params: {
                        page: this.query.pageIndex,
                        pageSize: this.query.pageSize,
                        sort: this.query.sort,
                        order: this.query.order,
                        fields: this.query.fields,
                        key: this.query.key
                    }
                };
                this.$axios.get('/Api/Admin/Products', config).then((res) => {
                    //console.log(res.data)
                    this.list = res.data.data
                    this.pager.pageIndex = res.data.pageIndex
                    this.pager.pageSize = res.data.pageSize
                    this.pager.totalPages = res.data.totalPages
                    this.pager.totalRecords = res.data.totalRecords
                })
            },
            loadTypes() {
                this.$axios.get('/Api/Admin/Products/Types').then((res) => {
                    this.types = res.data
                })
            },
            search() {
                this.query.pageIndex = 1;
                this.loadData();
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            onChangeQuery(queryParams) {
                var sort = queryParams.sort[0]
                this.query.sort = sort.name
                this.query.order = sort.order
                this.loadData()
            },
            loadItem(id) {
                this.$axios.get(`/Api/Admin/Products/${id}`)
                    .then((response) => {
                        //console.log(response)
                        let d = response.data
                        this.item = d
                    })
                    .catch( (error)=> {
                        console.log(error)
                    })
            },
            reset() {
                this.item.id = null
                this.item.title = null
                this.item.text = null
                this.item.type = null
                this.item.price = null
                this.item.originalPrice = null
            },
            create() {
                this.$refs.Edit.show()
                this.reset()
            },
            edit(id) {
                console.log(id)
                this.$refs.Edit.show()
                this.loadItem(id)
            },
            save() {
                this.updateing = true;
                if (this.item.id != null) {
                    this.$axios.put(`/Api/Admin/Products/${this.item.id}`, this.item)
                        .then((response) => {
                            this.reset()
                            this.loadData();
                            this.$refs.Edit.hide()
                            //this.loadData();
                            this.updateing = false;
                        })
                        .catch((error) => {
                            console.log(error)
                            this.updateing = false;
                        })
                } else {
                    this.$axios.post('/Api/Admin/Products', this.item)
                        .then((response) => {
                            this.reset()
                            this.loadData();
                            this.$refs.Edit.show()
                            this.updateing = false;
                        })
                        .catch((error) => {
                            console.log(error)
                            this.updateing = false;
                        })
                }
            },

        },
    }
</script>

<style lang="css">
    .RoleList .c1 {
        width: 90px;
    }
</style>
